<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
                <b-button variant="primary" :to="{ name: 'HotelNew' }">
                    <font-awesome-icon icon="plus"></font-awesome-icon> Nuevo
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Mostrar" append="registros">
                    <b-form-select v-model="hotels.itemsPerPage" id="perPageSelect" size="sm" :options="hotels.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>

            <!--<b-col sm="7" md="6" class="my-1">
                <b-input-group prepend="Filtrar: ">
                    <b-input v-model="hotels.filter" debounce="300"></b-input>
                </b-input-group>
            </b-col>-->
        </b-row>
        <b-table :items="loadData" :fields="hotels.fields" :filter="hotels.filter" :current-page="hotels.currentPage"
                 :per-page="hotels.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered hover small show-empty :responsive="true" :sort-by="hotels.sortBy" :sort-desc="hotels.sortDesc"
                 ref="hotelsTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in hotels.fields" :key="field.key"
                          :class="hotels.filters[index] && hotels.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="hotels.filters[index]">
                            <b-col v-if="hotels.filters[index].inputType === 'Select'">
                                <b-select v-model="hotels.filters[index].value">
                                    <b-select-option value="">Todos</b-select-option>
                                    <b-select-option value="TRUE">Activo</b-select-option>
                                    <b-select-option value="FALSE">Inactivo</b-select-option>
                                </b-select>
                            </b-col>
                            <b-col v-else-if="hotels.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="hotels.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(enabled)="row">
                <b-badge variant="primary" v-if="row.item.enabled" class="p-1">Activo</b-badge>
                <b-badge variant="warning" v-else>Inactivo</b-badge>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: 'HotelShow', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Detalle" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" :to="{ name: 'HotelEdit', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Modificar" variant="outline-primary">
                        <font-awesome-icon icon="edit"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" v-b-tooltip.hover :title="row.item.enabled ? 'Desactivar' : 'Activar'"
                              variant="outline-primary" @click="changeStatus(row.item)">
                        <font-awesome-icon :icon="row.item.enabled ? 'toggle-off' : 'toggle-on'"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Mostrando {{ start }} a {{ end }} de {{ hotels.totalRows }} registro<template v-if="hotels.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="hotels.currentPage" :total-rows="hotels.totalRows"
                                      :per-page="hotels.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { hotelListUrl, hotelEnableUrl } from '@routes';
import * as constants from '@constants';
import tableStateMixin from '../../mixins/tableState';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            hotels: {
                fields: [
                    // { key: 'id', label: 'id', sortable: true },
                    { key: 'name', label: 'Nombre', sortable: true },
                    { key: 'enabled', label: 'Estatus', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Acciones', class: 'text-center' }
                ],
                filters: [
                    { column: 'h.name', type: 'STRING', value: '', format: '' },
                    { column: 'h.enabled', type: 'BOOLEAN', value: '', format: '', inputType: 'Select' }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'h.name',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Hoteles',
                path: []
            }
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.hotels, this.tableState);
    },
    methods: {
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.hotels.filters.filter(filter => filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(hotelListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.hotels.totalRows = response.data.totalRows;
                        return response.data.hotels;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        changeStatus(hotel) {
            this.$swal({
                title: `¿${hotel.enabled ? 'Desactivar' : 'Activar'} el hotel ${hotel.name}?`,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.axios.put(hotelEnableUrl(), {
                        hotelId: hotel.id,
                        enable: !hotel.enabled
                    }).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            hotel.enabled = !hotel.enabled;

                            this.$bvToast.toast(response.data.message, {
                                title: 'Hoteles',
                                variant: 'success'
                            });

                            this.$refs.hotelsTable.refresh();
                            this.$emit('get-setting');
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al actualizar el estatus.', {
                            title: 'Hoteles',
                            variant: 'danger'
                        });
                    });
                }
            });
        },
        tableContextChanged(context) {
            this.hotels.sortBy = context.sortBy;
            this.hotels.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.hotels);
        }
    },
    watch: {
        'hotels.filters': {
            handler() {
                this.$refs.hotelsTable.refresh();
                this.saveTableState(this.$route.name, this.hotels);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.hotels.totalRows === 0 ? 0 : (this.hotels.currentPage - 1) * this.hotels.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.hotels.currentPage - 1) * this.hotels.itemsPerPage;

            return (this.hotels.totalRows - offset) < this.hotels.itemsPerPage ? this.hotels.totalRows : offset + this.hotels.itemsPerPage;
        }
    }
};
</script>

<style scoped>

</style>